/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel - Main Service API
 * Pinwheel - Main Service API
 *
 * The version of the OpenAPI document: 2.65.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActivateCellularRequestDto } from '../models';
// @ts-ignore
import { AppCategoriesResponse } from '../models';
// @ts-ignore
import { AppDescriptionsResponse } from '../models';
// @ts-ignore
import { AppTimeLimitRequestDto } from '../models';
// @ts-ignore
import { AppTimeLimitResponseDto } from '../models';
// @ts-ignore
import { AppUpdateRequestDto } from '../models';
// @ts-ignore
import { AppsResponse } from '../models';
// @ts-ignore
import { BasicModeResponse } from '../models';
// @ts-ignore
import { BatchDaysRequest } from '../models';
// @ts-ignore
import { BatchDaysResponse } from '../models';
// @ts-ignore
import { BillingEmailClaimRequest } from '../models';
// @ts-ignore
import { BillingEmailClaimResponse } from '../models';
// @ts-ignore
import { BillingEmailVerifyRequest } from '../models';
// @ts-ignore
import { BillingEmailVerifyResponse } from '../models';
// @ts-ignore
import { BillingLastInvoiceResponseDto } from '../models';
// @ts-ignore
import { BiometricTokenRequest } from '../models';
// @ts-ignore
import { BiometricTokenResponse } from '../models';
// @ts-ignore
import { BiometricVerifyTokenRequest } from '../models';
// @ts-ignore
import { BooleanResponse } from '../models';
// @ts-ignore
import { CalendarDayResponse } from '../models';
// @ts-ignore
import { CallLogResponse } from '../models';
// @ts-ignore
import { CallStatus } from '../models';
// @ts-ignore
import { CaregiverResponse } from '../models';
// @ts-ignore
import { CellularInfoResponse } from '../models';
// @ts-ignore
import { ChildResponse } from '../models';
// @ts-ignore
import { ContactGroupsResponse } from '../models';
// @ts-ignore
import { ContactsResponse } from '../models';
// @ts-ignore
import { ControlPlayStoreRequest } from '../models';
// @ts-ignore
import { CreateAppTimeLimitRequestDto } from '../models';
// @ts-ignore
import { CreateAppTimeLimitResponseDto } from '../models';
// @ts-ignore
import { CreateBillingRequest } from '../models';
// @ts-ignore
import { CreateBillingResponse } from '../models';
// @ts-ignore
import { CreateCalendarDayRequest } from '../models';
// @ts-ignore
import { CreateChildRequest } from '../models';
// @ts-ignore
import { CreateContactGroupRequest } from '../models';
// @ts-ignore
import { CreateContactGroupResponse } from '../models';
// @ts-ignore
import { CreateContactRequest } from '../models';
// @ts-ignore
import { CreateContactResponse } from '../models';
// @ts-ignore
import { CreateDayPresetRequest } from '../models';
// @ts-ignore
import { CreateFailedLocationRequest } from '../models';
// @ts-ignore
import { CreateModeRequest } from '../models';
// @ts-ignore
import { CreateRoutineRequest } from '../models';
// @ts-ignore
import { CreateSavedLocationRequest } from '../models';
// @ts-ignore
import { CreateWeekDaysRequest } from '../models';
// @ts-ignore
import { CreateWeekDaysResponse } from '../models';
// @ts-ignore
import { CustomerBillingInfoResponse } from '../models';
// @ts-ignore
import { CustomerUpdateRequest } from '../models';
// @ts-ignore
import { DayPresetResponse } from '../models';
// @ts-ignore
import { DayPresetsResponse } from '../models';
// @ts-ignore
import { DeleteChildResponse } from '../models';
// @ts-ignore
import { DeviceAuthVerifyRequest } from '../models';
// @ts-ignore
import { DeviceAuthVerifyResponse } from '../models';
// @ts-ignore
import { DeviceGroupsResponseDto } from '../models';
// @ts-ignore
import { DeviceLockRequest } from '../models';
// @ts-ignore
import { DeviceLockResponse } from '../models';
// @ts-ignore
import { DeviceUnlockRequest } from '../models';
// @ts-ignore
import { DeviceUnlockResponse } from '../models';
// @ts-ignore
import { ErrorResponseWithCode } from '../models';
// @ts-ignore
import { FailedLocationResponse } from '../models';
// @ts-ignore
import { FcmTokenRequest } from '../models';
// @ts-ignore
import { FcmTokenResponse } from '../models';
// @ts-ignore
import { FinalizeOnboardingRequest } from '../models';
// @ts-ignore
import { FinalizeOnboardingResponse } from '../models';
// @ts-ignore
import { FinalizeSubPurchaseRequest } from '../models';
// @ts-ignore
import { FinalizeSubPurchaseResponse } from '../models';
// @ts-ignore
import { GeolocationResponse } from '../models';
// @ts-ignore
import { InitiateSubPurchaseRequest } from '../models';
// @ts-ignore
import { InitiateSubPurchaseResponse } from '../models';
// @ts-ignore
import { LocationType } from '../models';
// @ts-ignore
import { ModesResponse } from '../models';
// @ts-ignore
import { ModifyDeviceGroupRequestDto } from '../models';
// @ts-ignore
import { ModifyGroupRequest } from '../models';
// @ts-ignore
import { ModifyGroupResponse } from '../models';
// @ts-ignore
import { ModifyModeAppRequest } from '../models';
// @ts-ignore
import { ModifyModeAppResponse } from '../models';
// @ts-ignore
import { ModifyModeContactGroupRequest } from '../models';
// @ts-ignore
import { ModifyModeContactGroupResponse } from '../models';
// @ts-ignore
import { NotificationInteraction } from '../models';
// @ts-ignore
import { NotificationLogResponseDto } from '../models';
// @ts-ignore
import { NotificationObjectCompletedAction } from '../models';
// @ts-ignore
import { NotificationObjectType } from '../models';
// @ts-ignore
import { NotificationSettingsResponseDto } from '../models';
// @ts-ignore
import { PageVisitRequestDto } from '../models';
// @ts-ignore
import { PageVisitResponseDto } from '../models';
// @ts-ignore
import { PaymentMethodSetupIntentResponse } from '../models';
// @ts-ignore
import { PaymentStatusResponse } from '../models';
// @ts-ignore
import { PlayStoreAppApproveRequest } from '../models';
// @ts-ignore
import { PlayStoreAppRejectRequest } from '../models';
// @ts-ignore
import { PlayStoreAppSubmissionCancelRequest } from '../models';
// @ts-ignore
import { PlayStoreAppSubmissionResponse } from '../models';
// @ts-ignore
import { RemoveAppTimeLimitRequestDto } from '../models';
// @ts-ignore
import { RemoveAppTimeLimitResponseDto } from '../models';
// @ts-ignore
import { ReportType } from '../models';
// @ts-ignore
import { RoutineResponse } from '../models';
// @ts-ignore
import { RoutinesResponse } from '../models';
// @ts-ignore
import { SavedLocationResponse } from '../models';
// @ts-ignore
import { SetPasscodeRequest } from '../models';
// @ts-ignore
import { ThreadResponse } from '../models';
// @ts-ignore
import { TimeSpanEnum } from '../models';
// @ts-ignore
import { TriggerGeolocationRequest } from '../models';
// @ts-ignore
import { TriggerGeolocationResponse } from '../models';
// @ts-ignore
import { UpdateCalendarDayRequest } from '../models';
// @ts-ignore
import { UpdateChildConfigRequest } from '../models';
// @ts-ignore
import { UpdateChildConfigResponse } from '../models';
// @ts-ignore
import { UpdateChildInfoRequest } from '../models';
// @ts-ignore
import { UpdateChildInfoResponse } from '../models';
// @ts-ignore
import { UpdateContactGroupRequest } from '../models';
// @ts-ignore
import { UpdateContactGroupResponse } from '../models';
// @ts-ignore
import { UpdateContactRequest } from '../models';
// @ts-ignore
import { UpdateContactResponse } from '../models';
// @ts-ignore
import { UpdateDayPresetRequest } from '../models';
// @ts-ignore
import { UpdateDeviceInfoRequest } from '../models';
// @ts-ignore
import { UpdateDeviceInfoResponse } from '../models';
// @ts-ignore
import { UpdateModeRequest } from '../models';
// @ts-ignore
import { UpdateNotificationLogDto } from '../models';
// @ts-ignore
import { UpdateNotificationSettingsDto } from '../models';
// @ts-ignore
import { UpdateRoutineRequest } from '../models';
// @ts-ignore
import { UpdateSavedLocationRequest } from '../models';
// @ts-ignore
import { UpdateWeekDayRequest } from '../models';
// @ts-ignore
import { UsageReportResponse } from '../models';
// @ts-ignore
import { WeekDayResponse } from '../models';
/**
 * CaregiverApi - axios parameter creator
 * @export
 */
export const CaregiverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('_delete', 'deviceId', deviceId)
            const localVarPath = `/main/fcmTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivateCellularRequestDto} activateCellularRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCellular: async (activateCellularRequestDto: ActivateCellularRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateCellularRequestDto' is not null or undefined
            assertParamExists('activateCellular', 'activateCellularRequestDto', activateCellularRequestDto)
            const localVarPath = `/main/cellular/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateCellularRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlayStoreAppSubmission: async (playStoreAppApproveRequest: PlayStoreAppApproveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppApproveRequest' is not null or undefined
            assertParamExists('approvePlayStoreAppSubmission', 'playStoreAppApproveRequest', playStoreAppApproveRequest)
            const localVarPath = `/main/apps/approvePlayStoreAppSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppApproveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPlayStoreAppSubmission: async (playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppSubmissionCancelRequest' is not null or undefined
            assertParamExists('cancelPlayStoreAppSubmission', 'playStoreAppSubmissionCancelRequest', playStoreAppSubmissionCancelRequest)
            const localVarPath = `/main/apps/cancelPlayStoreAppSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppSubmissionCancelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenIds] 
         * @param {boolean} [cancelBark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptions: async (childrenIds?: string, cancelBark?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenIds !== undefined) {
                localVarQueryParameter['childrenIds'] = childrenIds;
            }

            if (cancelBark !== undefined) {
                localVarQueryParameter['cancelBark'] = cancelBark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimBillingEmail: async (billingEmailClaimRequest: BillingEmailClaimRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingEmailClaimRequest' is not null or undefined
            assertParamExists('claimBillingEmail', 'billingEmailClaimRequest', billingEmailClaimRequest)
            const localVarPath = `/main/caregivers/billing/claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingEmailClaimRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlPlayStore: async (controlPlayStoreRequest: ControlPlayStoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlPlayStoreRequest' is not null or undefined
            assertParamExists('controlPlayStore', 'controlPlayStoreRequest', controlPlayStoreRequest)
            const localVarPath = `/main/apps/controlPlayStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlPlayStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FcmTokenRequest} fcmTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (fcmTokenRequest: FcmTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcmTokenRequest' is not null or undefined
            assertParamExists('create', 'fcmTokenRequest', fcmTokenRequest)
            const localVarPath = `/main/fcmTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fcmTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFailedLocationRequest} createFailedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (createFailedLocationRequest: CreateFailedLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFailedLocationRequest' is not null or undefined
            assertParamExists('create1', 'createFailedLocationRequest', createFailedLocationRequest)
            const localVarPath = `/events/failedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFailedLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBillingRequest} createBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBilling: async (createBillingRequest: CreateBillingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBillingRequest' is not null or undefined
            assertParamExists('createBilling', 'createBillingRequest', createBillingRequest)
            const localVarPath = `/main/caregivers/billing/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBillingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BiometricTokenRequest} biometricTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiometricToken: async (biometricTokenRequest: BiometricTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biometricTokenRequest' is not null or undefined
            assertParamExists('createBiometricToken', 'biometricTokenRequest', biometricTokenRequest)
            const localVarPath = `/main/biometrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biometricTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCalendarDayRequest} createCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarDay: async (createCalendarDayRequest: CreateCalendarDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalendarDayRequest' is not null or undefined
            assertParamExists('createCalendarDay', 'createCalendarDayRequest', createCalendarDayRequest)
            const localVarPath = `/main/dayPresets/calendarDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateChildRequest} createChildRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChild: async (createChildRequest: CreateChildRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChildRequest' is not null or undefined
            assertParamExists('createChild', 'createChildRequest', createChildRequest)
            const localVarPath = `/main/caregivers/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChildRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateContactRequest} createContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (createContactRequest: CreateContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContactRequest' is not null or undefined
            assertParamExists('createContact', 'createContactRequest', createContactRequest)
            const localVarPath = `/main/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateContactGroupRequest} createContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactGroup: async (createContactGroupRequest: CreateContactGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContactGroupRequest' is not null or undefined
            assertParamExists('createContactGroup', 'createContactGroupRequest', createContactGroupRequest)
            const localVarPath = `/main/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDayPresetRequest} createDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDayPreset: async (createDayPresetRequest: CreateDayPresetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDayPresetRequest' is not null or undefined
            assertParamExists('createDayPreset', 'createDayPresetRequest', createDayPresetRequest)
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDayPresetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceLockRequest} deviceLockRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceLock: async (deviceLockRequest: DeviceLockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLockRequest' is not null or undefined
            assertParamExists('createDeviceLock', 'deviceLockRequest', deviceLockRequest)
            const localVarPath = `/events/deviceLock/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceUnlockRequest} deviceUnlockRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceUnlock: async (deviceUnlockRequest: DeviceUnlockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUnlockRequest' is not null or undefined
            assertParamExists('createDeviceUnlock', 'deviceUnlockRequest', deviceUnlockRequest)
            const localVarPath = `/events/deviceLock/unlock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceUnlockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateModeRequest} createModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMode: async (createModeRequest: CreateModeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createModeRequest' is not null or undefined
            assertParamExists('createMode', 'createModeRequest', createModeRequest)
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRoutineRequest} createRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoutine: async (createRoutineRequest: CreateRoutineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoutineRequest' is not null or undefined
            assertParamExists('createRoutine', 'createRoutineRequest', createRoutineRequest)
            const localVarPath = `/main/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoutineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSavedLocationRequest} createSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedLocation: async (createSavedLocationRequest: CreateSavedLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSavedLocationRequest' is not null or undefined
            assertParamExists('createSavedLocation', 'createSavedLocationRequest', createSavedLocationRequest)
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSavedLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWeekDaysRequest} createWeekDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWeekDays: async (createWeekDaysRequest: CreateWeekDaysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWeekDaysRequest' is not null or undefined
            assertParamExists('createWeekDays', 'createWeekDaysRequest', createWeekDaysRequest)
            const localVarPath = `/main/dayPresets/weekDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWeekDaysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBiometricToken: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteBiometricToken', 'deviceId', deviceId)
            const localVarPath = `/main/biometrics/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChild: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('deleteChild', 'childId', childId)
            const localVarPath = `/main/children/{childId}`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContact', 'id', id)
            const localVarPath = `/main/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dayPresetId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDayPreset: async (dayPresetId: string, replacementId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dayPresetId' is not null or undefined
            assertParamExists('deleteDayPreset', 'dayPresetId', dayPresetId)
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dayPresetId !== undefined) {
                localVarQueryParameter['dayPresetId'] = dayPresetId;
            }

            if (replacementId !== undefined) {
                localVarQueryParameter['replacementId'] = replacementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modeId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMode: async (modeId: string, replacementId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modeId' is not null or undefined
            assertParamExists('deleteMode', 'modeId', modeId)
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (modeId !== undefined) {
                localVarQueryParameter['modeId'] = modeId;
            }

            if (replacementId !== undefined) {
                localVarQueryParameter['replacementId'] = replacementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoutine: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoutine', 'id', id)
            const localVarPath = `/main/routines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedLocation: async (id: string, childrenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSavedLocation', 'id', id)
            // verify required parameter 'childrenId' is not null or undefined
            assertParamExists('deleteSavedLocation', 'childrenId', childrenId)
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeOnboarding: async (finalizeOnboardingRequest: FinalizeOnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizeOnboardingRequest' is not null or undefined
            assertParamExists('finalizeOnboarding', 'finalizeOnboardingRequest', finalizeOnboardingRequest)
            const localVarPath = `/main/children/finalizeOnboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeOnboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinalizeSubPurchaseRequest} finalizeSubPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeSubPurchase: async (finalizeSubPurchaseRequest: FinalizeSubPurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizeSubPurchaseRequest' is not null or undefined
            assertParamExists('finalizeSubPurchase', 'finalizeSubPurchaseRequest', finalizeSubPurchaseRequest)
            const localVarPath = `/main/caregivers/billing/finalizeSubPurchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeSubPurchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/appCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/appDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeInfoApps] 
         * @param {Array<string>} [supportedInWatchDevices] Filter to include only apps that are supported in the specified watch devices. Example: \&#39;supportedInWatchDevices&#x3D;watch1,watch2\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps: async (includeInfoApps?: boolean, supportedInWatchDevices?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (includeInfoApps !== undefined) {
                localVarQueryParameter['includeInfoApps'] = includeInfoApps;
            }

            if (supportedInWatchDevices) {
                localVarQueryParameter['supportedInWatchDevices'] = supportedInWatchDevices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsByIds: async (appIds: string, includeCategories?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appIds' is not null or undefined
            assertParamExists('getAppsByIds', 'appIds', appIds)
            const localVarPath = `/main/apps/appsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (appIds !== undefined) {
                localVarQueryParameter['appIds'] = appIds;
            }

            if (includeCategories !== undefined) {
                localVarQueryParameter['includeCategories'] = includeCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannedApps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/apps/bannedApps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [since] Date string like dd/mm/yyyy
         * @param {string} [until] Date string like dd/mm/yyyy
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarDays: async (since?: string, until?: string, childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/dayPresets/calendarDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CallStatus} [status] 
         * @param {string} [childrenId] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [contactNumbers] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallLog: async (status?: CallStatus, childrenId?: string, offset?: number, limit?: number, contactNumbers?: Array<string>, since?: Date, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/callLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (contactNumbers) {
                localVarQueryParameter['contactNumbers'] = contactNumbers;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaregiver: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCellularInfo: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getCellularInfo', 'childId', childId)
            const localVarPath = `/main/cellular/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildContactGroups: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildContactGroups', 'childId', childId)
            const localVarPath = `/main/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildContacts: async (childId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {Array<LocationType>} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildLocations: async (childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildLocations', 'childId', childId)
            const localVarPath = `/main/geolocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPresets: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {any} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceGroups: async (childId: string, deviceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getDeviceGroups', 'childId', childId)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceGroups', 'deviceId', deviceId)
            const localVarPath = `/main/deviceControl/deviceGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastCellularInvoice: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getLastCellularInvoice', 'childId', childId)
            const localVarPath = `/main/caregivers/billing/lastCellularInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childrenId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [threadId] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (childrenId: string, offset?: number, limit?: number, threadId?: string, since?: Date, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childrenId' is not null or undefined
            assertParamExists('getMessages', 'childrenId', childrenId)
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (threadId !== undefined) {
                localVarQueryParameter['threadId'] = threadId;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModes: async (childId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
         * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
         * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
         * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
         * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
         * @param {string} [childrenId] 
         * @param {Date} [since] 
         * @param {string} [notificationObjectId] 
         * @param {boolean} [isActionCompleted] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (notificationObjectType) {
                localVarQueryParameter['notificationObjectType'] = notificationObjectType;
            }

            if (interaction) {
                localVarQueryParameter['interaction'] = interaction;
            }

            if (excludeInteraction) {
                localVarQueryParameter['excludeInteraction'] = excludeInteraction;
            }

            if (completedAction) {
                localVarQueryParameter['completedAction'] = completedAction;
            }

            if (excludeCompletedAction) {
                localVarQueryParameter['excludeCompletedAction'] = excludeCompletedAction;
            }

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (notificationObjectId !== undefined) {
                localVarQueryParameter['notificationObjectId'] = notificationObjectId;
            }

            if (isActionCompleted !== undefined) {
                localVarQueryParameter['isActionCompleted'] = isActionCompleted;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodSetupIntent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/paymentMethodSetupIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayStoreAppSubmissions: async (childId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/apps/playStoreAppSubmissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewMessages: async (childrenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childrenId' is not null or undefined
            assertParamExists('getPreviewMessages', 'childrenId', childrenId)
            const localVarPath = `/messages/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoutines: async (childId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedLocations: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPaymentStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/caregivers/billing/paymentStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ReportType>} reports 
         * @param {TimeSpanEnum} timeSpan 
         * @param {string} childId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageReport: async (reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reports' is not null or undefined
            assertParamExists('getUsageReport', 'reports', reports)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getUsageReport', 'timeSpan', timeSpan)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getUsageReport', 'childId', childId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getUsageReport', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getUsageReport', 'month', month)
            const localVarPath = `/main/reports/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (reports) {
                localVarQueryParameter['reports'] = reports;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ReportType>} reports 
         * @param {TimeSpanEnum} timeSpan 
         * @param {string} childId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageReport_1: async (reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reports' is not null or undefined
            assertParamExists('getUsageReport_1', 'reports', reports)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getUsageReport_1', 'timeSpan', timeSpan)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getUsageReport_1', 'childId', childId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getUsageReport_1', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getUsageReport_1', 'month', month)
            const localVarPath = `/main/reports/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (reports) {
                localVarQueryParameter['reports'] = reports;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekDays: async (childrenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/dayPresets/weekDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (childrenId !== undefined) {
                localVarQueryParameter['childrenId'] = childrenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitiateSubPurchaseRequest} initiateSubPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateSubPurchase: async (initiateSubPurchaseRequest: InitiateSubPurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateSubPurchaseRequest' is not null or undefined
            assertParamExists('initiateSubPurchase', 'initiateSubPurchaseRequest', initiateSubPurchaseRequest)
            const localVarPath = `/main/caregivers/billing/initiateSubPurchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateSubPurchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyGroupRequest} modifyGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyContactGroups: async (modifyGroupRequest: ModifyGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyGroupRequest' is not null or undefined
            assertParamExists('modifyContactGroups', 'modifyGroupRequest', modifyGroupRequest)
            const localVarPath = `/main/contacts/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceGroup: async (modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyDeviceGroupRequestDto' is not null or undefined
            assertParamExists('modifyDeviceGroup', 'modifyDeviceGroupRequestDto', modifyDeviceGroupRequestDto)
            const localVarPath = `/main/deviceControl/deviceGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyDeviceGroupRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyModeAppRequest} modifyModeAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeAppRelations: async (modifyModeAppRequest: ModifyModeAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyModeAppRequest' is not null or undefined
            assertParamExists('modifyModeAppRelations', 'modifyModeAppRequest', modifyModeAppRequest)
            const localVarPath = `/main/modes/modeApps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyModeAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeContactGroupRelations: async (modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyModeContactGroupRequest' is not null or undefined
            assertParamExists('modifyModeContactGroupRelations', 'modifyModeContactGroupRequest', modifyModeContactGroupRequest)
            const localVarPath = `/main/modes/modeContactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyModeContactGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPlayStoreAppSubmission: async (playStoreAppRejectRequest: PlayStoreAppRejectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playStoreAppRejectRequest' is not null or undefined
            assertParamExists('rejectPlayStoreAppSubmission', 'playStoreAppRejectRequest', playStoreAppRejectRequest)
            const localVarPath = `/main/apps/rejectPlayStoreAppSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playStoreAppRejectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppTimeLimit: async (removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeAppTimeLimitRequestDto' is not null or undefined
            assertParamExists('removeAppTimeLimit', 'removeAppTimeLimitRequestDto', removeAppTimeLimitRequestDto)
            const localVarPath = `/main/apps/removeWeeklyTimeLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeAppTimeLimitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppTimeLimit: async (appTimeLimitRequestDto: AppTimeLimitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appTimeLimitRequestDto' is not null or undefined
            assertParamExists('saveAppTimeLimit', 'appTimeLimitRequestDto', appTimeLimitRequestDto)
            const localVarPath = `/events/analytics/appTimeLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appTimeLimitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageVisitRequestDto} pageVisitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePageVisit: async (pageVisitRequestDto: PageVisitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageVisitRequestDto' is not null or undefined
            assertParamExists('savePageVisit', 'pageVisitRequestDto', pageVisitRequestDto)
            const localVarPath = `/events/analytics/pageVisits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageVisitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppUpdateRequestDto} appUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAppUpdateRequest: async (appUpdateRequestDto: AppUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appUpdateRequestDto' is not null or undefined
            assertParamExists('sendAppUpdateRequest', 'appUpdateRequestDto', appUpdateRequestDto)
            const localVarPath = `/main/apps/appUpdateRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAppTimeLimit: async (createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppTimeLimitRequestDto' is not null or undefined
            assertParamExists('setAppTimeLimit', 'createAppTimeLimitRequestDto', createAppTimeLimitRequestDto)
            const localVarPath = `/main/apps/weeklyTimeLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppTimeLimitRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasscodeRequest} setPasscodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasscode: async (setPasscodeRequest: SetPasscodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasscodeRequest' is not null or undefined
            assertParamExists('setPasscode', 'setPasscodeRequest', setPasscodeRequest)
            const localVarPath = `/main/deviceControl/setPasscode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasscodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutCaregiverGlobally: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('signOutCaregiverGlobally', 'email', email)
            const localVarPath = `/main/public/caregivers/globalSignOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutGlobally: async (childId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('signOutGlobally', 'childId', childId)
            const localVarPath = `/main/children/{childId}/globalSignOut`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerGeolocation: async (triggerGeolocationRequest: TriggerGeolocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerGeolocationRequest' is not null or undefined
            assertParamExists('triggerGeolocation', 'triggerGeolocationRequest', triggerGeolocationRequest)
            const localVarPath = `/main/geolocations/trigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(triggerGeolocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchDaysRequest} batchDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchDays: async (batchDaysRequest: BatchDaysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchDaysRequest' is not null or undefined
            assertParamExists('updateBatchDays', 'batchDaysRequest', batchDaysRequest)
            const localVarPath = `/main/dayPresets/batchDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchDaysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarDay: async (updateCalendarDayRequest: UpdateCalendarDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCalendarDayRequest' is not null or undefined
            assertParamExists('updateCalendarDay', 'updateCalendarDayRequest', updateCalendarDayRequest)
            const localVarPath = `/main/dayPresets/calendarDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalendarDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildConfigRequest} updateChildConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildConfig: async (childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateChildConfig', 'childId', childId)
            // verify required parameter 'updateChildConfigRequest' is not null or undefined
            assertParamExists('updateChildConfig', 'updateChildConfigRequest', updateChildConfigRequest)
            const localVarPath = `/main/children/{childId}/config`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChildConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildInfoRequest} updateChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildInfo: async (childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateChildInfo', 'childId', childId)
            // verify required parameter 'updateChildInfoRequest' is not null or undefined
            assertParamExists('updateChildInfo', 'updateChildInfoRequest', updateChildInfoRequest)
            const localVarPath = `/main/children/{childId}/info`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChildInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateContactRequest} updateContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (updateContactRequest: UpdateContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateContactRequest' is not null or undefined
            assertParamExists('updateContact', 'updateContactRequest', updateContactRequest)
            const localVarPath = `/main/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateContactGroupRequest} updateContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactGroup: async (updateContactGroupRequest: UpdateContactGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateContactGroupRequest' is not null or undefined
            assertParamExists('updateContactGroup', 'updateContactGroupRequest', updateContactGroupRequest)
            const localVarPath = `/main/contactGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerUpdateRequest} customerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerInfo: async (customerUpdateRequest: CustomerUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUpdateRequest' is not null or undefined
            assertParamExists('updateCustomerInfo', 'customerUpdateRequest', customerUpdateRequest)
            const localVarPath = `/main/caregivers/billing/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDayPresetRequest} updateDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDayPreset: async (updateDayPresetRequest: UpdateDayPresetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDayPresetRequest' is not null or undefined
            assertParamExists('updateDayPreset', 'updateDayPresetRequest', updateDayPresetRequest)
            const localVarPath = `/main/dayPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDayPresetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceInfo: async (childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateDeviceInfo', 'childId', childId)
            // verify required parameter 'updateDeviceInfoRequest' is not null or undefined
            assertParamExists('updateDeviceInfo', 'updateDeviceInfoRequest', updateDeviceInfoRequest)
            const localVarPath = `/main/children/{childId}/deviceInfo`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateModeRequest} updateModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMode: async (updateModeRequest: UpdateModeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateModeRequest' is not null or undefined
            assertParamExists('updateMode', 'updateModeRequest', updateModeRequest)
            const localVarPath = `/main/modes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings: async (updateNotificationSettingsDto: UpdateNotificationSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationSettingsDto' is not null or undefined
            assertParamExists('updateNotificationSettings', 'updateNotificationSettingsDto', updateNotificationSettingsDto)
            const localVarPath = `/main/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications: async (updateNotificationLogDto: Array<UpdateNotificationLogDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationLogDto' is not null or undefined
            assertParamExists('updateNotifications', 'updateNotificationLogDto', updateNotificationLogDto)
            const localVarPath = `/main/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRoutineRequest} updateRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoutine: async (updateRoutineRequest: UpdateRoutineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRoutineRequest' is not null or undefined
            assertParamExists('updateRoutine', 'updateRoutineRequest', updateRoutineRequest)
            const localVarPath = `/main/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoutineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSavedLocation: async (updateSavedLocationRequest: UpdateSavedLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSavedLocationRequest' is not null or undefined
            assertParamExists('updateSavedLocation', 'updateSavedLocationRequest', updateSavedLocationRequest)
            const localVarPath = `/main/geolocations/savedLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSavedLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWeekDayRequest} updateWeekDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeekDay: async (updateWeekDayRequest: UpdateWeekDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWeekDayRequest' is not null or undefined
            assertParamExists('updateWeekDay', 'updateWeekDayRequest', updateWeekDayRequest)
            const localVarPath = `/main/dayPresets/weekDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWeekDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyBillingEmail: async (billingEmailVerifyRequest: BillingEmailVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingEmailVerifyRequest' is not null or undefined
            assertParamExists('verifyBillingEmail', 'billingEmailVerifyRequest', billingEmailVerifyRequest)
            const localVarPath = `/main/caregivers/billing/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingEmailVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BiometricVerifyTokenRequest} biometricVerifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyBiometricToken: async (biometricVerifyTokenRequest: BiometricVerifyTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biometricVerifyTokenRequest' is not null or undefined
            assertParamExists('verifyBiometricToken', 'biometricVerifyTokenRequest', biometricVerifyTokenRequest)
            const localVarPath = `/main/biometrics/verifyToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biometricVerifyTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceAuthVerifyRequest} deviceAuthVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyLoginCode: async (deviceAuthVerifyRequest: DeviceAuthVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceAuthVerifyRequest' is not null or undefined
            assertParamExists('verifyLoginCode', 'deviceAuthVerifyRequest', deviceAuthVerifyRequest)
            const localVarPath = `/main/deviceAuth/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAuthVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaregiverApi - functional programming interface
 * @export
 */
export const CaregiverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaregiverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FcmTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActivateCellularRequestDto} activateCellularRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCellular(activateCellularRequestDto: ActivateCellularRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellularInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCellular(activateCellularRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePlayStoreAppSubmission(playStoreAppApproveRequest: PlayStoreAppApproveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePlayStoreAppSubmission(playStoreAppApproveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenIds] 
         * @param {boolean} [cancelBark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscriptions(childrenIds?: string, cancelBark?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscriptions(childrenIds, cancelBark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimBillingEmail(billingEmailClaimRequest: BillingEmailClaimRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingEmailClaimResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimBillingEmail(billingEmailClaimRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlPlayStore(controlPlayStoreRequest: ControlPlayStoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlPlayStore(controlPlayStoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FcmTokenRequest} fcmTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(fcmTokenRequest: FcmTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FcmTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(fcmTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateFailedLocationRequest} createFailedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(createFailedLocationRequest: CreateFailedLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(createFailedLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBillingRequest} createBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBilling(createBillingRequest: CreateBillingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBillingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBilling(createBillingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BiometricTokenRequest} biometricTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBiometricToken(biometricTokenRequest: BiometricTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiometricTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBiometricToken(biometricTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCalendarDayRequest} createCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendarDay(createCalendarDayRequest: CreateCalendarDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendarDay(createCalendarDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateChildRequest} createChildRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChild(createChildRequest: CreateChildRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChild(createChildRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateContactRequest} createContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(createContactRequest: CreateContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateContactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(createContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateContactGroupRequest} createContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactGroup(createContactGroupRequest: CreateContactGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateContactGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactGroup(createContactGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDayPresetRequest} createDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDayPreset(createDayPresetRequest: CreateDayPresetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDayPreset(createDayPresetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceLockRequest} deviceLockRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeviceLock(deviceLockRequest: DeviceLockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceLockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeviceLock(deviceLockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceUnlockRequest} deviceUnlockRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeviceUnlock(deviceUnlockRequest: DeviceUnlockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceUnlockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeviceUnlock(deviceUnlockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateModeRequest} createModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMode(createModeRequest: CreateModeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicModeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMode(createModeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRoutineRequest} createRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoutine(createRoutineRequest: CreateRoutineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoutine(createRoutineRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSavedLocationRequest} createSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSavedLocation(createSavedLocationRequest: CreateSavedLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSavedLocation(createSavedLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWeekDaysRequest} createWeekDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWeekDays(createWeekDaysRequest: CreateWeekDaysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWeekDaysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWeekDays(createWeekDaysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBiometricToken(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBiometricToken(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChild(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteChildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChild(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dayPresetId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDayPreset(dayPresetId: string, replacementId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDayPreset(dayPresetId, replacementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modeId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMode(modeId: string, replacementId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMode(modeId, replacementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoutine(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoutine(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedLocation(id: string, childrenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedLocation(id, childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeOnboarding(finalizeOnboardingRequest: FinalizeOnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizeOnboardingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeOnboarding(finalizeOnboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinalizeSubPurchaseRequest} finalizeSubPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeSubPurchase(finalizeSubPurchaseRequest: FinalizeSubPurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizeSubPurchaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeSubPurchase(finalizeSubPurchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppDescriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeInfoApps] 
         * @param {Array<string>} [supportedInWatchDevices] Filter to include only apps that are supported in the specified watch devices. Example: \&#39;supportedInWatchDevices&#x3D;watch1,watch2\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApps(includeInfoApps?: boolean, supportedInWatchDevices?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApps(includeInfoApps, supportedInWatchDevices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppsByIds(appIds: string, includeCategories?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppsByIds(appIds, includeCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBannedApps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBannedApps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBillingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [since] Date string like dd/mm/yyyy
         * @param {string} [until] Date string like dd/mm/yyyy
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarDays(since?: string, until?: string, childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarDayResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarDays(since, until, childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CallStatus} [status] 
         * @param {string} [childrenId] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [contactNumbers] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallLog(status?: CallStatus, childrenId?: string, offset?: number, limit?: number, contactNumbers?: Array<string>, since?: Date, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CallLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallLog(status, childrenId, offset, limit, contactNumbers, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaregiver(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaregiverResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaregiver(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCellularInfo(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellularInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCellularInfo(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildContactGroups(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactGroupsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildContactGroups(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildContacts(childId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildContacts(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {Array<LocationType>} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildLocations(childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeolocationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildLocations(childId, type, limit, offset, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildren(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChildResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildren(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayPresets(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayPresetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayPresets(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {any} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceGroups(childId: string, deviceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceGroupsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceGroups(childId, deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastCellularInvoice(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingLastInvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastCellularInvoice(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childrenId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [threadId] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(childrenId: string, offset?: number, limit?: number, threadId?: string, since?: Date, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(childrenId, offset, limit, threadId, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModes(childId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModes(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
         * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
         * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
         * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
         * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
         * @param {string} [childrenId] 
         * @param {Date} [since] 
         * @param {string} [notificationObjectId] 
         * @param {boolean} [isActionCompleted] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationLogResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(notificationObjectType, interaction, excludeInteraction, completedAction, excludeCompletedAction, childrenId, since, notificationObjectId, isActionCompleted, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethodSetupIntent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodSetupIntentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethodSetupIntent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayStoreAppSubmissions(childId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayStoreAppSubmissionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayStoreAppSubmissions(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewMessages(childrenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewMessages(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoutines(childId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutinesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoutines(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedLocations(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedLocationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedLocations(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionPaymentStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionPaymentStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ReportType>} reports 
         * @param {TimeSpanEnum} timeSpan 
         * @param {string} childId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsageReport(reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageReport(reports, timeSpan, childId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ReportType>} reports 
         * @param {TimeSpanEnum} timeSpan 
         * @param {string} childId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsageReport_1(reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageReport_1(reports, timeSpan, childId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekDays(childrenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeekDayResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekDays(childrenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitiateSubPurchaseRequest} initiateSubPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateSubPurchase(initiateSubPurchaseRequest: InitiateSubPurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitiateSubPurchaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateSubPurchase(initiateSubPurchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyGroupRequest} modifyGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyContactGroups(modifyGroupRequest: ModifyGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyContactGroups(modifyGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDeviceGroup(modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyDeviceGroup(modifyDeviceGroupRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyModeAppRequest} modifyModeAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyModeAppRelations(modifyModeAppRequest: ModifyModeAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyModeAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyModeAppRelations(modifyModeAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyModeContactGroupRelations(modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyModeContactGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyModeContactGroupRelations(modifyModeContactGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectPlayStoreAppSubmission(playStoreAppRejectRequest: PlayStoreAppRejectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectPlayStoreAppSubmission(playStoreAppRejectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAppTimeLimit(removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveAppTimeLimitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAppTimeLimit(removeAppTimeLimitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAppTimeLimit(appTimeLimitRequestDto: AppTimeLimitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppTimeLimitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAppTimeLimit(appTimeLimitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageVisitRequestDto} pageVisitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePageVisit(pageVisitRequestDto: PageVisitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVisitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePageVisit(pageVisitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppUpdateRequestDto} appUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAppUpdateRequest(appUpdateRequestDto: AppUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAppUpdateRequest(appUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAppTimeLimit(createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAppTimeLimitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAppTimeLimit(createAppTimeLimitRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetPasscodeRequest} setPasscodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPasscode(setPasscodeRequest: SetPasscodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPasscode(setPasscodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signOutCaregiverGlobally(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signOutCaregiverGlobally(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signOutGlobally(childId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signOutGlobally(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerGeolocation(triggerGeolocationRequest: TriggerGeolocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerGeolocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerGeolocation(triggerGeolocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BatchDaysRequest} batchDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatchDays(batchDaysRequest: BatchDaysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchDaysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBatchDays(batchDaysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarDay(updateCalendarDayRequest: UpdateCalendarDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarDay(updateCalendarDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildConfigRequest} updateChildConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChildConfig(childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateChildConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChildConfig(childId, updateChildConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildInfoRequest} updateChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChildInfo(childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateChildInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChildInfo(childId, updateChildInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateContactRequest} updateContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(updateContactRequest: UpdateContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateContactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(updateContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateContactGroupRequest} updateContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactGroup(updateContactGroupRequest: UpdateContactGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateContactGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactGroup(updateContactGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerUpdateRequest} customerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerInfo(customerUpdateRequest: CustomerUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBillingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerInfo(customerUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDayPresetRequest} updateDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDayPreset(updateDayPresetRequest: UpdateDayPresetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DayPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDayPreset(updateDayPresetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceInfo(childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceInfo(childId, updateDeviceInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateModeRequest} updateModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMode(updateModeRequest: UpdateModeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicModeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMode(updateModeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationSettings(updateNotificationSettingsDto: UpdateNotificationSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationSettings(updateNotificationSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(updateNotificationLogDto: Array<UpdateNotificationLogDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifications(updateNotificationLogDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateRoutineRequest} updateRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoutine(updateRoutineRequest: UpdateRoutineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoutine(updateRoutineRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSavedLocation(updateSavedLocationRequest: UpdateSavedLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSavedLocation(updateSavedLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWeekDayRequest} updateWeekDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWeekDay(updateWeekDayRequest: UpdateWeekDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeekDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWeekDay(updateWeekDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyBillingEmail(billingEmailVerifyRequest: BillingEmailVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingEmailVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyBillingEmail(billingEmailVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BiometricVerifyTokenRequest} biometricVerifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyBiometricToken(biometricVerifyTokenRequest: BiometricVerifyTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyBiometricToken(biometricVerifyTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceAuthVerifyRequest} deviceAuthVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyLoginCode(deviceAuthVerifyRequest: DeviceAuthVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAuthVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyLoginCode(deviceAuthVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaregiverApi - factory interface
 * @export
 */
export const CaregiverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaregiverApiFp(configuration)
    return {
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(deviceId: string, options?: any): AxiosPromise<FcmTokenResponse> {
            return localVarFp._delete(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivateCellularRequestDto} activateCellularRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCellular(activateCellularRequestDto: ActivateCellularRequestDto, options?: any): AxiosPromise<CellularInfoResponse> {
            return localVarFp.activateCellular(activateCellularRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlayStoreAppSubmission(playStoreAppApproveRequest: PlayStoreAppApproveRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.approvePlayStoreAppSubmission(playStoreAppApproveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenIds] 
         * @param {boolean} [cancelBark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptions(childrenIds?: string, cancelBark?: boolean, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.cancelSubscriptions(childrenIds, cancelBark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimBillingEmail(billingEmailClaimRequest: BillingEmailClaimRequest, options?: any): AxiosPromise<BillingEmailClaimResponse> {
            return localVarFp.claimBillingEmail(billingEmailClaimRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlPlayStore(controlPlayStoreRequest: ControlPlayStoreRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.controlPlayStore(controlPlayStoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FcmTokenRequest} fcmTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(fcmTokenRequest: FcmTokenRequest, options?: any): AxiosPromise<FcmTokenResponse> {
            return localVarFp.create(fcmTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateFailedLocationRequest} createFailedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(createFailedLocationRequest: CreateFailedLocationRequest, options?: any): AxiosPromise<FailedLocationResponse> {
            return localVarFp.create1(createFailedLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBillingRequest} createBillingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBilling(createBillingRequest: CreateBillingRequest, options?: any): AxiosPromise<CreateBillingResponse> {
            return localVarFp.createBilling(createBillingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BiometricTokenRequest} biometricTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiometricToken(biometricTokenRequest: BiometricTokenRequest, options?: any): AxiosPromise<BiometricTokenResponse> {
            return localVarFp.createBiometricToken(biometricTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCalendarDayRequest} createCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarDay(createCalendarDayRequest: CreateCalendarDayRequest, options?: any): AxiosPromise<CalendarDayResponse> {
            return localVarFp.createCalendarDay(createCalendarDayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateChildRequest} createChildRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChild(createChildRequest: CreateChildRequest, options?: any): AxiosPromise<ChildResponse> {
            return localVarFp.createChild(createChildRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateContactRequest} createContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(createContactRequest: CreateContactRequest, options?: any): AxiosPromise<CreateContactResponse> {
            return localVarFp.createContact(createContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateContactGroupRequest} createContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactGroup(createContactGroupRequest: CreateContactGroupRequest, options?: any): AxiosPromise<CreateContactGroupResponse> {
            return localVarFp.createContactGroup(createContactGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDayPresetRequest} createDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDayPreset(createDayPresetRequest: CreateDayPresetRequest, options?: any): AxiosPromise<DayPresetResponse> {
            return localVarFp.createDayPreset(createDayPresetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceLockRequest} deviceLockRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceLock(deviceLockRequest: DeviceLockRequest, options?: any): AxiosPromise<DeviceLockResponse> {
            return localVarFp.createDeviceLock(deviceLockRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceUnlockRequest} deviceUnlockRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceUnlock(deviceUnlockRequest: DeviceUnlockRequest, options?: any): AxiosPromise<DeviceUnlockResponse> {
            return localVarFp.createDeviceUnlock(deviceUnlockRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateModeRequest} createModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMode(createModeRequest: CreateModeRequest, options?: any): AxiosPromise<BasicModeResponse> {
            return localVarFp.createMode(createModeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRoutineRequest} createRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoutine(createRoutineRequest: CreateRoutineRequest, options?: any): AxiosPromise<RoutineResponse> {
            return localVarFp.createRoutine(createRoutineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSavedLocationRequest} createSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedLocation(createSavedLocationRequest: CreateSavedLocationRequest, options?: any): AxiosPromise<SavedLocationResponse> {
            return localVarFp.createSavedLocation(createSavedLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWeekDaysRequest} createWeekDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWeekDays(createWeekDaysRequest: CreateWeekDaysRequest, options?: any): AxiosPromise<CreateWeekDaysResponse> {
            return localVarFp.createWeekDays(createWeekDaysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBiometricToken(deviceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBiometricToken(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChild(childId: string, options?: any): AxiosPromise<DeleteChildResponse> {
            return localVarFp.deleteChild(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dayPresetId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDayPreset(dayPresetId: string, replacementId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteDayPreset(dayPresetId, replacementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modeId 
         * @param {string} [replacementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMode(modeId: string, replacementId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteMode(modeId, replacementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoutine(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteRoutine(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedLocation(id: string, childrenId: string, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.deleteSavedLocation(id, childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeOnboarding(finalizeOnboardingRequest: FinalizeOnboardingRequest, options?: any): AxiosPromise<FinalizeOnboardingResponse> {
            return localVarFp.finalizeOnboarding(finalizeOnboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinalizeSubPurchaseRequest} finalizeSubPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeSubPurchase(finalizeSubPurchaseRequest: FinalizeSubPurchaseRequest, options?: any): AxiosPromise<FinalizeSubPurchaseResponse> {
            return localVarFp.finalizeSubPurchase(finalizeSubPurchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCategories(options?: any): AxiosPromise<AppCategoriesResponse> {
            return localVarFp.getAppCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDescriptions(options?: any): AxiosPromise<AppDescriptionsResponse> {
            return localVarFp.getAppDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeInfoApps] 
         * @param {Array<string>} [supportedInWatchDevices] Filter to include only apps that are supported in the specified watch devices. Example: \&#39;supportedInWatchDevices&#x3D;watch1,watch2\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps(includeInfoApps?: boolean, supportedInWatchDevices?: Array<string>, options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getApps(includeInfoApps, supportedInWatchDevices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appIds 
         * @param {boolean} [includeCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppsByIds(appIds: string, includeCategories?: boolean, options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getAppsByIds(appIds, includeCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannedApps(options?: any): AxiosPromise<AppsResponse> {
            return localVarFp.getBannedApps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo(options?: any): AxiosPromise<CustomerBillingInfoResponse> {
            return localVarFp.getBillingInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [since] Date string like dd/mm/yyyy
         * @param {string} [until] Date string like dd/mm/yyyy
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarDays(since?: string, until?: string, childrenId?: string, options?: any): AxiosPromise<Array<CalendarDayResponse>> {
            return localVarFp.getCalendarDays(since, until, childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CallStatus} [status] 
         * @param {string} [childrenId] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [contactNumbers] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallLog(status?: CallStatus, childrenId?: string, offset?: number, limit?: number, contactNumbers?: Array<string>, since?: Date, options?: any): AxiosPromise<Array<CallLogResponse>> {
            return localVarFp.getCallLog(status, childrenId, offset, limit, contactNumbers, since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaregiver(options?: any): AxiosPromise<CaregiverResponse> {
            return localVarFp.getCaregiver(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCellularInfo(childId: string, options?: any): AxiosPromise<CellularInfoResponse> {
            return localVarFp.getCellularInfo(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildContactGroups(childId: string, options?: any): AxiosPromise<Array<ContactGroupsResponse>> {
            return localVarFp.getChildContactGroups(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildContacts(childId?: string, options?: any): AxiosPromise<ContactsResponse> {
            return localVarFp.getChildContacts(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {Array<LocationType>} [type] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildLocations(childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options?: any): AxiosPromise<Array<GeolocationResponse>> {
            return localVarFp.getChildLocations(childId, type, limit, offset, since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren(options?: any): AxiosPromise<Array<ChildResponse>> {
            return localVarFp.getChildren(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPresets(childrenId?: string, options?: any): AxiosPromise<DayPresetsResponse> {
            return localVarFp.getDayPresets(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {any} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceGroups(childId: string, deviceId: any, options?: any): AxiosPromise<DeviceGroupsResponseDto> {
            return localVarFp.getDeviceGroups(childId, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastCellularInvoice(childId: string, options?: any): AxiosPromise<BillingLastInvoiceResponseDto> {
            return localVarFp.getLastCellularInvoice(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childrenId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [threadId] 
         * @param {Date} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(childrenId: string, offset?: number, limit?: number, threadId?: string, since?: Date, options?: any): AxiosPromise<Array<ThreadResponse>> {
            return localVarFp.getMessages(childrenId, offset, limit, threadId, since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModes(childId?: string, options?: any): AxiosPromise<ModesResponse> {
            return localVarFp.getModes(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings(options?: any): AxiosPromise<NotificationSettingsResponseDto> {
            return localVarFp.getNotificationSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
         * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
         * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
         * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
         * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
         * @param {string} [childrenId] 
         * @param {Date} [since] 
         * @param {string} [notificationObjectId] 
         * @param {boolean} [isActionCompleted] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options?: any): AxiosPromise<Array<NotificationLogResponseDto>> {
            return localVarFp.getNotifications(notificationObjectType, interaction, excludeInteraction, completedAction, excludeCompletedAction, childrenId, since, notificationObjectId, isActionCompleted, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodSetupIntent(options?: any): AxiosPromise<PaymentMethodSetupIntentResponse> {
            return localVarFp.getPaymentMethodSetupIntent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayStoreAppSubmissions(childId?: string, options?: any): AxiosPromise<Array<PlayStoreAppSubmissionResponse>> {
            return localVarFp.getPlayStoreAppSubmissions(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childrenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewMessages(childrenId: string, options?: any): AxiosPromise<Array<ThreadResponse>> {
            return localVarFp.getPreviewMessages(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoutines(childId?: string, options?: any): AxiosPromise<RoutinesResponse> {
            return localVarFp.getRoutines(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedLocations(childrenId?: string, options?: any): AxiosPromise<Array<SavedLocationResponse>> {
            return localVarFp.getSavedLocations(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPaymentStatus(options?: any): AxiosPromise<PaymentStatusResponse> {
            return localVarFp.getSubscriptionPaymentStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ReportType>} reports 
         * @param {TimeSpanEnum} timeSpan 
         * @param {string} childId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageReport(reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options?: any): AxiosPromise<UsageReportResponse> {
            return localVarFp.getUsageReport(reports, timeSpan, childId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ReportType>} reports 
         * @param {TimeSpanEnum} timeSpan 
         * @param {string} childId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageReport_1(reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options?: any): AxiosPromise<UsageReportResponse> {
            return localVarFp.getUsageReport_1(reports, timeSpan, childId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [childrenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekDays(childrenId?: string, options?: any): AxiosPromise<Array<WeekDayResponse>> {
            return localVarFp.getWeekDays(childrenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InitiateSubPurchaseRequest} initiateSubPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateSubPurchase(initiateSubPurchaseRequest: InitiateSubPurchaseRequest, options?: any): AxiosPromise<InitiateSubPurchaseResponse> {
            return localVarFp.initiateSubPurchase(initiateSubPurchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyGroupRequest} modifyGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyContactGroups(modifyGroupRequest: ModifyGroupRequest, options?: any): AxiosPromise<ModifyGroupResponse> {
            return localVarFp.modifyContactGroups(modifyGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceGroup(modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.modifyDeviceGroup(modifyDeviceGroupRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyModeAppRequest} modifyModeAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeAppRelations(modifyModeAppRequest: ModifyModeAppRequest, options?: any): AxiosPromise<ModifyModeAppResponse> {
            return localVarFp.modifyModeAppRelations(modifyModeAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyModeContactGroupRelations(modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options?: any): AxiosPromise<ModifyModeContactGroupResponse> {
            return localVarFp.modifyModeContactGroupRelations(modifyModeContactGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPlayStoreAppSubmission(playStoreAppRejectRequest: PlayStoreAppRejectRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.rejectPlayStoreAppSubmission(playStoreAppRejectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppTimeLimit(removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options?: any): AxiosPromise<RemoveAppTimeLimitResponseDto> {
            return localVarFp.removeAppTimeLimit(removeAppTimeLimitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppTimeLimit(appTimeLimitRequestDto: AppTimeLimitRequestDto, options?: any): AxiosPromise<AppTimeLimitResponseDto> {
            return localVarFp.saveAppTimeLimit(appTimeLimitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageVisitRequestDto} pageVisitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePageVisit(pageVisitRequestDto: PageVisitRequestDto, options?: any): AxiosPromise<PageVisitResponseDto> {
            return localVarFp.savePageVisit(pageVisitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppUpdateRequestDto} appUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAppUpdateRequest(appUpdateRequestDto: AppUpdateRequestDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.sendAppUpdateRequest(appUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAppTimeLimit(createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options?: any): AxiosPromise<CreateAppTimeLimitResponseDto> {
            return localVarFp.setAppTimeLimit(createAppTimeLimitRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPasscodeRequest} setPasscodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasscode(setPasscodeRequest: SetPasscodeRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.setPasscode(setPasscodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutCaregiverGlobally(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.signOutCaregiverGlobally(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signOutGlobally(childId: string, options?: any): AxiosPromise<void> {
            return localVarFp.signOutGlobally(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerGeolocation(triggerGeolocationRequest: TriggerGeolocationRequest, options?: any): AxiosPromise<TriggerGeolocationResponse> {
            return localVarFp.triggerGeolocation(triggerGeolocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchDaysRequest} batchDaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatchDays(batchDaysRequest: BatchDaysRequest, options?: any): AxiosPromise<BatchDaysResponse> {
            return localVarFp.updateBatchDays(batchDaysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarDay(updateCalendarDayRequest: UpdateCalendarDayRequest, options?: any): AxiosPromise<CalendarDayResponse> {
            return localVarFp.updateCalendarDay(updateCalendarDayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildConfigRequest} updateChildConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildConfig(childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options?: any): AxiosPromise<UpdateChildConfigResponse> {
            return localVarFp.updateChildConfig(childId, updateChildConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateChildInfoRequest} updateChildInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildInfo(childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options?: any): AxiosPromise<UpdateChildInfoResponse> {
            return localVarFp.updateChildInfo(childId, updateChildInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateContactRequest} updateContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(updateContactRequest: UpdateContactRequest, options?: any): AxiosPromise<UpdateContactResponse> {
            return localVarFp.updateContact(updateContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateContactGroupRequest} updateContactGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactGroup(updateContactGroupRequest: UpdateContactGroupRequest, options?: any): AxiosPromise<UpdateContactGroupResponse> {
            return localVarFp.updateContactGroup(updateContactGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerUpdateRequest} customerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerInfo(customerUpdateRequest: CustomerUpdateRequest, options?: any): AxiosPromise<CustomerBillingInfoResponse> {
            return localVarFp.updateCustomerInfo(customerUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDayPresetRequest} updateDayPresetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDayPreset(updateDayPresetRequest: UpdateDayPresetRequest, options?: any): AxiosPromise<DayPresetResponse> {
            return localVarFp.updateDayPreset(updateDayPresetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} childId 
         * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceInfo(childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options?: any): AxiosPromise<UpdateDeviceInfoResponse> {
            return localVarFp.updateDeviceInfo(childId, updateDeviceInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateModeRequest} updateModeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMode(updateModeRequest: UpdateModeRequest, options?: any): AxiosPromise<BasicModeResponse> {
            return localVarFp.updateMode(updateModeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings(updateNotificationSettingsDto: UpdateNotificationSettingsDto, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateNotificationSettings(updateNotificationSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(updateNotificationLogDto: Array<UpdateNotificationLogDto>, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateNotifications(updateNotificationLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRoutineRequest} updateRoutineRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoutine(updateRoutineRequest: UpdateRoutineRequest, options?: any): AxiosPromise<RoutineResponse> {
            return localVarFp.updateRoutine(updateRoutineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSavedLocation(updateSavedLocationRequest: UpdateSavedLocationRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.updateSavedLocation(updateSavedLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWeekDayRequest} updateWeekDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeekDay(updateWeekDayRequest: UpdateWeekDayRequest, options?: any): AxiosPromise<WeekDayResponse> {
            return localVarFp.updateWeekDay(updateWeekDayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyBillingEmail(billingEmailVerifyRequest: BillingEmailVerifyRequest, options?: any): AxiosPromise<BillingEmailVerifyResponse> {
            return localVarFp.verifyBillingEmail(billingEmailVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BiometricVerifyTokenRequest} biometricVerifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyBiometricToken(biometricVerifyTokenRequest: BiometricVerifyTokenRequest, options?: any): AxiosPromise<BooleanResponse> {
            return localVarFp.verifyBiometricToken(biometricVerifyTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceAuthVerifyRequest} deviceAuthVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyLoginCode(deviceAuthVerifyRequest: DeviceAuthVerifyRequest, options?: any): AxiosPromise<DeviceAuthVerifyResponse> {
            return localVarFp.verifyLoginCode(deviceAuthVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaregiverApi - object-oriented interface
 * @export
 * @class CaregiverApi
 * @extends {BaseAPI}
 */
export class CaregiverApi extends BaseAPI {
    /**
     * 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public _delete(deviceId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration)._delete(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivateCellularRequestDto} activateCellularRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public activateCellular(activateCellularRequestDto: ActivateCellularRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).activateCellular(activateCellularRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppApproveRequest} playStoreAppApproveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public approvePlayStoreAppSubmission(playStoreAppApproveRequest: PlayStoreAppApproveRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).approvePlayStoreAppSubmission(playStoreAppApproveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppSubmissionCancelRequest} playStoreAppSubmissionCancelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest: PlayStoreAppSubmissionCancelRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).cancelPlayStoreAppSubmission(playStoreAppSubmissionCancelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenIds] 
     * @param {boolean} [cancelBark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public cancelSubscriptions(childrenIds?: string, cancelBark?: boolean, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).cancelSubscriptions(childrenIds, cancelBark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingEmailClaimRequest} billingEmailClaimRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public claimBillingEmail(billingEmailClaimRequest: BillingEmailClaimRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).claimBillingEmail(billingEmailClaimRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlPlayStoreRequest} controlPlayStoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public controlPlayStore(controlPlayStoreRequest: ControlPlayStoreRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).controlPlayStore(controlPlayStoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FcmTokenRequest} fcmTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public create(fcmTokenRequest: FcmTokenRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).create(fcmTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateFailedLocationRequest} createFailedLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public create1(createFailedLocationRequest: CreateFailedLocationRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).create1(createFailedLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBillingRequest} createBillingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createBilling(createBillingRequest: CreateBillingRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createBilling(createBillingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BiometricTokenRequest} biometricTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createBiometricToken(biometricTokenRequest: BiometricTokenRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createBiometricToken(biometricTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCalendarDayRequest} createCalendarDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createCalendarDay(createCalendarDayRequest: CreateCalendarDayRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createCalendarDay(createCalendarDayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateChildRequest} createChildRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createChild(createChildRequest: CreateChildRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createChild(createChildRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateContactRequest} createContactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createContact(createContactRequest: CreateContactRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createContact(createContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateContactGroupRequest} createContactGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createContactGroup(createContactGroupRequest: CreateContactGroupRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createContactGroup(createContactGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDayPresetRequest} createDayPresetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createDayPreset(createDayPresetRequest: CreateDayPresetRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createDayPreset(createDayPresetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceLockRequest} deviceLockRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createDeviceLock(deviceLockRequest: DeviceLockRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createDeviceLock(deviceLockRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceUnlockRequest} deviceUnlockRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createDeviceUnlock(deviceUnlockRequest: DeviceUnlockRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createDeviceUnlock(deviceUnlockRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateModeRequest} createModeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createMode(createModeRequest: CreateModeRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createMode(createModeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateRoutineRequest} createRoutineRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createRoutine(createRoutineRequest: CreateRoutineRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createRoutine(createRoutineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSavedLocationRequest} createSavedLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createSavedLocation(createSavedLocationRequest: CreateSavedLocationRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createSavedLocation(createSavedLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWeekDaysRequest} createWeekDaysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public createWeekDays(createWeekDaysRequest: CreateWeekDaysRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).createWeekDays(createWeekDaysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteBiometricToken(deviceId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteBiometricToken(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteChild(childId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteChild(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteContact(id: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dayPresetId 
     * @param {string} [replacementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteDayPreset(dayPresetId: string, replacementId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteDayPreset(dayPresetId, replacementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modeId 
     * @param {string} [replacementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteMode(modeId: string, replacementId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteMode(modeId, replacementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteRoutine(id: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteRoutine(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} childrenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public deleteSavedLocation(id: string, childrenId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).deleteSavedLocation(id, childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinalizeOnboardingRequest} finalizeOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public finalizeOnboarding(finalizeOnboardingRequest: FinalizeOnboardingRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).finalizeOnboarding(finalizeOnboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinalizeSubPurchaseRequest} finalizeSubPurchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public finalizeSubPurchase(finalizeSubPurchaseRequest: FinalizeSubPurchaseRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).finalizeSubPurchase(finalizeSubPurchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getAppCategories(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getAppCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getAppDescriptions(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getAppDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeInfoApps] 
     * @param {Array<string>} [supportedInWatchDevices] Filter to include only apps that are supported in the specified watch devices. Example: \&#39;supportedInWatchDevices&#x3D;watch1,watch2\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getApps(includeInfoApps?: boolean, supportedInWatchDevices?: Array<string>, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getApps(includeInfoApps, supportedInWatchDevices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appIds 
     * @param {boolean} [includeCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getAppsByIds(appIds: string, includeCategories?: boolean, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getAppsByIds(appIds, includeCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getBannedApps(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getBannedApps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getBillingInfo(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getBillingInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [since] Date string like dd/mm/yyyy
     * @param {string} [until] Date string like dd/mm/yyyy
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getCalendarDays(since?: string, until?: string, childrenId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getCalendarDays(since, until, childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CallStatus} [status] 
     * @param {string} [childrenId] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {Array<string>} [contactNumbers] 
     * @param {Date} [since] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getCallLog(status?: CallStatus, childrenId?: string, offset?: number, limit?: number, contactNumbers?: Array<string>, since?: Date, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getCallLog(status, childrenId, offset, limit, contactNumbers, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getCaregiver(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getCaregiver(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getCellularInfo(childId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getCellularInfo(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getChildContactGroups(childId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getChildContactGroups(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getChildContacts(childId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getChildContacts(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {Array<LocationType>} [type] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {Date} [since] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getChildLocations(childId: string, type?: Array<LocationType>, limit?: number, offset?: number, since?: Date, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getChildLocations(childId, type, limit, offset, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getChildren(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getChildren(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getDayPresets(childrenId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getDayPresets(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {any} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getDeviceGroups(childId: string, deviceId: any, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getDeviceGroups(childId, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getLastCellularInvoice(childId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getLastCellularInvoice(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childrenId 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {string} [threadId] 
     * @param {Date} [since] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getMessages(childrenId: string, offset?: number, limit?: number, threadId?: string, since?: Date, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getMessages(childrenId, offset, limit, threadId, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getModes(childId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getModes(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getNotificationSettings(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getNotificationSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<NotificationObjectType>} [notificationObjectType] NotificationObjectType
     * @param {Array<NotificationInteraction>} [interaction] NotificationInteraction
     * @param {Array<NotificationInteraction>} [excludeInteraction] NotificationInteraction
     * @param {Array<NotificationObjectCompletedAction>} [completedAction] NotificationObjectCompletedAction
     * @param {Array<NotificationObjectCompletedAction>} [excludeCompletedAction] NotificationObjectCompletedAction
     * @param {string} [childrenId] 
     * @param {Date} [since] 
     * @param {string} [notificationObjectId] 
     * @param {boolean} [isActionCompleted] 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getNotifications(notificationObjectType?: Array<NotificationObjectType>, interaction?: Array<NotificationInteraction>, excludeInteraction?: Array<NotificationInteraction>, completedAction?: Array<NotificationObjectCompletedAction>, excludeCompletedAction?: Array<NotificationObjectCompletedAction>, childrenId?: string, since?: Date, notificationObjectId?: string, isActionCompleted?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getNotifications(notificationObjectType, interaction, excludeInteraction, completedAction, excludeCompletedAction, childrenId, since, notificationObjectId, isActionCompleted, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getPaymentMethodSetupIntent(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getPaymentMethodSetupIntent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getPlayStoreAppSubmissions(childId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getPlayStoreAppSubmissions(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childrenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getPreviewMessages(childrenId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getPreviewMessages(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getRoutines(childId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getRoutines(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getSavedLocations(childrenId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getSavedLocations(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getSubscriptionPaymentStatus(options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getSubscriptionPaymentStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ReportType>} reports 
     * @param {TimeSpanEnum} timeSpan 
     * @param {string} childId 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getUsageReport(reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getUsageReport(reports, timeSpan, childId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ReportType>} reports 
     * @param {TimeSpanEnum} timeSpan 
     * @param {string} childId 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getUsageReport_1(reports: Array<ReportType>, timeSpan: TimeSpanEnum, childId: string, year: number, month: number, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getUsageReport_1(reports, timeSpan, childId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [childrenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public getWeekDays(childrenId?: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).getWeekDays(childrenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InitiateSubPurchaseRequest} initiateSubPurchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public initiateSubPurchase(initiateSubPurchaseRequest: InitiateSubPurchaseRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).initiateSubPurchase(initiateSubPurchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyGroupRequest} modifyGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public modifyContactGroups(modifyGroupRequest: ModifyGroupRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).modifyContactGroups(modifyGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyDeviceGroupRequestDto} modifyDeviceGroupRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public modifyDeviceGroup(modifyDeviceGroupRequestDto: ModifyDeviceGroupRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).modifyDeviceGroup(modifyDeviceGroupRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyModeAppRequest} modifyModeAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public modifyModeAppRelations(modifyModeAppRequest: ModifyModeAppRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).modifyModeAppRelations(modifyModeAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyModeContactGroupRequest} modifyModeContactGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public modifyModeContactGroupRelations(modifyModeContactGroupRequest: ModifyModeContactGroupRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).modifyModeContactGroupRelations(modifyModeContactGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlayStoreAppRejectRequest} playStoreAppRejectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public rejectPlayStoreAppSubmission(playStoreAppRejectRequest: PlayStoreAppRejectRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).rejectPlayStoreAppSubmission(playStoreAppRejectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveAppTimeLimitRequestDto} removeAppTimeLimitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public removeAppTimeLimit(removeAppTimeLimitRequestDto: RemoveAppTimeLimitRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).removeAppTimeLimit(removeAppTimeLimitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppTimeLimitRequestDto} appTimeLimitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public saveAppTimeLimit(appTimeLimitRequestDto: AppTimeLimitRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).saveAppTimeLimit(appTimeLimitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageVisitRequestDto} pageVisitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public savePageVisit(pageVisitRequestDto: PageVisitRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).savePageVisit(pageVisitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppUpdateRequestDto} appUpdateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public sendAppUpdateRequest(appUpdateRequestDto: AppUpdateRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).sendAppUpdateRequest(appUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppTimeLimitRequestDto} createAppTimeLimitRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public setAppTimeLimit(createAppTimeLimitRequestDto: CreateAppTimeLimitRequestDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).setAppTimeLimit(createAppTimeLimitRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPasscodeRequest} setPasscodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public setPasscode(setPasscodeRequest: SetPasscodeRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).setPasscode(setPasscodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public signOutCaregiverGlobally(email: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).signOutCaregiverGlobally(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public signOutGlobally(childId: string, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).signOutGlobally(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TriggerGeolocationRequest} triggerGeolocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public triggerGeolocation(triggerGeolocationRequest: TriggerGeolocationRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).triggerGeolocation(triggerGeolocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchDaysRequest} batchDaysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateBatchDays(batchDaysRequest: BatchDaysRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateBatchDays(batchDaysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCalendarDayRequest} updateCalendarDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateCalendarDay(updateCalendarDayRequest: UpdateCalendarDayRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateCalendarDay(updateCalendarDayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {UpdateChildConfigRequest} updateChildConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateChildConfig(childId: string, updateChildConfigRequest: UpdateChildConfigRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateChildConfig(childId, updateChildConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {UpdateChildInfoRequest} updateChildInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateChildInfo(childId: string, updateChildInfoRequest: UpdateChildInfoRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateChildInfo(childId, updateChildInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateContactRequest} updateContactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateContact(updateContactRequest: UpdateContactRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateContact(updateContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateContactGroupRequest} updateContactGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateContactGroup(updateContactGroupRequest: UpdateContactGroupRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateContactGroup(updateContactGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerUpdateRequest} customerUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateCustomerInfo(customerUpdateRequest: CustomerUpdateRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateCustomerInfo(customerUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDayPresetRequest} updateDayPresetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateDayPreset(updateDayPresetRequest: UpdateDayPresetRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateDayPreset(updateDayPresetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} childId 
     * @param {UpdateDeviceInfoRequest} updateDeviceInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateDeviceInfo(childId: string, updateDeviceInfoRequest: UpdateDeviceInfoRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateDeviceInfo(childId, updateDeviceInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateModeRequest} updateModeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateMode(updateModeRequest: UpdateModeRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateMode(updateModeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNotificationSettingsDto} updateNotificationSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateNotificationSettings(updateNotificationSettingsDto: UpdateNotificationSettingsDto, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateNotificationSettings(updateNotificationSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateNotificationLogDto>} updateNotificationLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateNotifications(updateNotificationLogDto: Array<UpdateNotificationLogDto>, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateNotifications(updateNotificationLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRoutineRequest} updateRoutineRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateRoutine(updateRoutineRequest: UpdateRoutineRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateRoutine(updateRoutineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSavedLocationRequest} updateSavedLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateSavedLocation(updateSavedLocationRequest: UpdateSavedLocationRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateSavedLocation(updateSavedLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWeekDayRequest} updateWeekDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public updateWeekDay(updateWeekDayRequest: UpdateWeekDayRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).updateWeekDay(updateWeekDayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingEmailVerifyRequest} billingEmailVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public verifyBillingEmail(billingEmailVerifyRequest: BillingEmailVerifyRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).verifyBillingEmail(billingEmailVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BiometricVerifyTokenRequest} biometricVerifyTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public verifyBiometricToken(biometricVerifyTokenRequest: BiometricVerifyTokenRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).verifyBiometricToken(biometricVerifyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceAuthVerifyRequest} deviceAuthVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaregiverApi
     */
    public verifyLoginCode(deviceAuthVerifyRequest: DeviceAuthVerifyRequest, options?: AxiosRequestConfig) {
        return CaregiverApiFp(this.configuration).verifyLoginCode(deviceAuthVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
