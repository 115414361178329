export const PinwheelGPTIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5292 13.3738C11.2866 13.3738 11.9007 12.7598 11.9007 12.0023C11.9007 11.2449 11.2866 10.6309 10.5292 10.6309C9.77174 10.6309 9.15771 11.2449 9.15771 12.0023C9.15771 12.7598 9.77174 13.3738 10.5292 13.3738Z"
        fill="#F8C923"
      />
      <path
        d="M9.93918 7.88734C9.37337 7.88734 8.91211 7.42609 8.91211 6.86028C8.91211 6.49127 9.10891 6.15302 9.42257 5.96851L12.7067 4.06813C14.2996 3.15177 16.2553 3.15177 17.8482 4.06813L20.5419 5.62411C21.0339 5.90701 21.2061 6.54047 20.9171 7.03248C20.6342 7.52449 20.0007 7.69669 19.5087 7.40764L16.815 5.85166C15.8617 5.3043 14.687 5.3043 13.7276 5.85166L10.4496 7.75204C10.2959 7.84429 10.1175 7.88734 9.93918 7.88734Z"
        fill="#573AE2"
      />
      <path
        d="M5.78097 10.9743C5.21516 10.9743 4.75391 10.513 4.75391 9.94723V6.14647C4.75391 4.31374 5.73177 2.61631 7.31849 1.6938L10.0122 0.137821C10.5042 -0.145083 11.1316 0.0209696 11.4206 0.512977C11.7035 1.00499 11.5375 1.6323 11.0454 1.92135L8.35171 3.47732C7.39845 4.03083 6.80804 5.0456 6.80804 6.14647V9.94108C6.80804 10.513 6.34678 10.9743 5.78097 10.9743Z"
        fill="#01B5B1"
      />
      <path
        d="M10.5285 24.0008C9.96272 24.0008 9.50146 23.5395 9.50146 22.9737C9.50146 22.6047 9.69827 22.2665 10.0181 22.082L12.7118 20.526C13.6651 19.9725 14.2555 18.9577 14.2555 17.8568V14.0622C14.2555 13.4964 14.7167 13.0352 15.2825 13.0352C15.8484 13.0352 16.3096 13.4964 16.3096 14.0622V17.8568C16.3096 19.6957 15.3256 21.387 13.7389 22.3095L11.0451 23.8655C10.8852 23.9516 10.713 24.0008 10.5285 24.0008Z"
        fill="#01B5B1"
      />
      <path
        d="M5.78165 20.618C4.87759 20.618 3.99198 20.3843 3.20476 19.9292L0.517175 18.3793C0.0251676 18.0964 -0.147035 17.463 0.142019 16.971C0.424923 16.4789 1.05838 16.3067 1.55039 16.5958L4.23798 18.1456C5.19124 18.693 6.36591 18.693 7.32532 18.1456L10.6095 16.2452C11.1015 15.9623 11.7288 16.1284 12.0178 16.6204C12.3007 17.1124 12.1347 17.7397 11.6427 18.0288L8.35239 19.9292C7.57133 20.3781 6.68572 20.618 5.78165 20.618Z"
        fill="#573AE2"
      />
      <path
        d="M20.0308 18.5135C19.4649 18.5135 19.0037 18.0522 19.0037 17.4864V14.3745C19.0037 13.2736 18.4133 12.2527 17.46 11.7053L14.1759 9.80494C13.6839 9.52204 13.5117 8.88858 13.8007 8.39657C14.0836 7.90456 14.7171 7.73236 15.2091 8.02141L18.4932 9.92179C20.08 10.8443 21.064 12.5356 21.064 14.3745V17.4864C21.0578 18.0522 20.5966 18.5135 20.0308 18.5135Z"
        fill="#E97814"
      />
      <path
        d="M6.37149 16.1167C6.19314 16.1167 6.01479 16.0675 5.86104 15.9814L2.57074 14.081C0.984014 13.1585 0.00615009 11.4611 0 9.62833V6.51638C0.0123002 5.95058 0.485857 5.50162 1.05167 5.51392C1.59902 5.52622 2.04183 5.96903 2.05413 6.51638V9.62833C2.05413 10.7292 2.64454 11.7501 3.5978 12.2975L6.8881 14.1979C7.38011 14.4808 7.54616 15.1081 7.26326 15.6001C7.07875 15.9199 6.7405 16.1167 6.37149 16.1167Z"
        fill="#E97814"
      />
    </svg>
  );
};
