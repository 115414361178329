/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel - Main Service API
 * Pinwheel - Main Service API
 *
 * The version of the OpenAPI document: 2.65.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum NotificationInteraction {
    Seen = 'Seen',
    Dismissed = 'Dismissed'
}


